* {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Montserrat', sans-serif;
}

.main-wrapper {
  background-color: black;
  text-align: center;
  height: 100vh;
}

.main-title {
  font-size: 40px;
}

.buttons-wrapper{
  border-radius: 20px;
  margin: 0 2%;
}

.button-wrapper {
  /* background-color: rgba(255, 255, 255, 1); */
  border: 2px solid rgb(255, 255, 255);
  border-radius: 2px;
  padding: 3px 0px;
  margin: 3% 4%;
  align-items: center;
  color: black;
  font-weight: 400;
  height: 55px;
  -webkit-box-shadow: 0px 0px 3px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 3px -1px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 3px -1px rgba(0,0,0,0.75);
}

.logo{
  border-radius: 10px; 
}

.button-wrapper:hover {
  background-color: rgb(255, 255, 255);
}

.button-wrapper:hover img {
  filter: invert(100%) sepia() saturate(10000%) hue-rotate(0deg);
}

.button-img{
  margin-top: 5px;
}

.link{
  text-decoration: none;
}

.link:hover{
  background-color: white;
}
.link:visited{
  color: black;
}

#tiktok-logo img {
  filter: invert(100%) sepia(100%) saturate(10%) hue-rotate(0deg);
}

#tiktok-logo:hover img {
  filter: none;
}

@media only screen and (min-width: 1028px) {
  .buttons-wrapper{
    margin: 0% 20%;
  }
}
